import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import Container from "../../../components/Container";

import { db } from "../../../services/firebase";
import { collection, getDocs } from "firebase/firestore";

function ClientList() {
  const [searchTerm, setSearchTerm] = useState("");
  const [clients, setClients] = useState([]);

  // Função para buscar os clientes do backend

  const fetchClientsFirebase = async () => {
    try {
      const snapshot = await getDocs(collection(db, "clientes"));
      const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      console.log(data);
      setClients(data);
    } catch (error) {
      console.error("Erro ao buscar clientes:", error);
    }
  };

  useEffect(() => {
    fetchClientsFirebase();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredClients = clients.filter(
    (client) =>
      client.nomeEmpresa.toLowerCase().includes(searchTerm.toLowerCase()) ||
      client.cnpj.includes(searchTerm)
  );

  return (
    <Container>
      <div className="client-list-container">
        <h2>Clientes Cadastrados</h2>
        <input
          type="text"
          placeholder="Buscar por nome ou CNPJ"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <table className="client-table">
          <thead>
            <tr>
              <th>Ação</th>
              <th>Nome</th>
              <th>CNPJ</th>
              <th>Unidade</th> {/* Ajustado para Unidade */}
            </tr>
          </thead>
          <tbody>
            {filteredClients.length > 0 ? (
              filteredClients.map((client) => (
                <tr key={client.id}>
                  <td>
                    <Link to={`/admin/clientes/${client.id}`}>
                      <img
                        src="/assets/icon-show.png"
                        alt="Visualizar cliente"
                        title="Visualizar cliente"
                        className="view-icon"
                      />
                    </Link>
                  </td>
                  <td>{client.nomeEmpresa}</td>
                  <td>{client.cnpj}</td>
                  <td>{client.unidade}</td> {/* Campo Unidade */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">Nenhum cliente encontrado</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Container>
  );
}

export default ClientList;

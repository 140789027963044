import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./styles.css";
import Container from "../../../components/Container";
import { Link } from "react-router-dom";
import { db } from "../../../services/firebase";
import {
  doc,
  getDoc,
  query,
  getDocs,
  collection,
  where,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

function ClientDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState(null);
  const [valves, setValves] = useState([]); // Estado para armazenar válvulas vinculadas
  const [users, setUsers] = useState([]); // Estado para armazenar usuarios vinculadas
  const [isEditing, setIsEditing] = useState(false);

  // Buscar detalhes do cliente
  const fetchClientDetails = useCallback(async () => {
    try {
      const ref = doc(db, "clientes", id);
      const snapshot = await getDoc(ref);

      const data = { id: snapshot.id, ...snapshot.data() };
      setClient(data);
    } catch (error) {
      console.error("Erro ao buscar detalhes do cliente:", error);
    }
  }, [id]);

  // Buscar válvulas vinculadas ao cliente
  const fetchValves = useCallback(async () => {
    try {
      const snapshot = await getDocs(
        query(collection(db, "valvulas"), where("cliente_id", "==", id))
      );
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setValves(data);
    } catch (error) {
      console.error("Erro ao buscar válvulas do cliente:", error);
    }
  }, [id]);

  const fetchUsers = useCallback(async () => {
    try {
      const snapshot = await getDocs(
        query(collection(db, "usuarios"), where("cliente_id", "==", id))
      );
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(data);
    } catch (error) {
      console.error("Erro ao buscar usuários do cliente:", error);
    }
  }, [id]);

  useEffect(() => {
    fetchClientDetails();
    fetchValves();
    fetchUsers();
  }, [fetchClientDetails, fetchValves, fetchUsers]); // array de dependencias

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClient((prevClient) => ({
      ...prevClient,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      const ref = doc(db, "clientes", id);
      await updateDoc(ref, client);
      alert("Cliente atualizado com sucesso!");
      setIsEditing(false);
    } catch (error) {
      console.error("Erro ao atualizar cliente:", error);
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Tem certeza de que deseja excluir este cliente?")) {
      try {
        await deleteDoc(doc(db, "clientes", id));
        alert("Cliente deletado com sucesso!");
        navigate("/admin/clientes");
      } catch (error) {
        console.error("Erro ao deletar cliente:", error);
        alert(
          "Erro ao deletar cliente. Verifique o console para mais detalhes."
        );
      }
    }
  };

  if (!client) {
    return <div>Carregando...</div>;
  }

  return (
    <Container>
      <div className="client-details-container">
        <h2>Detalhes do Cliente</h2>
        <div className="form-group">
          <label>Nome:</label>
          <input
            type="text"
            name="nomeEmpresa"
            value={client.nomeEmpresa}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <label>CNPJ:</label>
          <input
            type="text"
            name="cnpj"
            value={client.cnpj}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <label>Unidade:</label>
          <input
            type="text"
            name="unidade"
            value={client.unidade}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
      </div>
      <h2>Usuários</h2>
      <table className="client-table">
        <thead>
          <tr>
            <th>Ação</th>
            <th>Nome</th>
            <th>Login</th>
          </tr>
        </thead>
        <tbody>
          {users.length > 0 ? (
            users.map((user) => (
              <tr key={user.id}>
                <td style={{ width: "25px" }}>
                  <Link to={`/admin/usuario/${user.id}`}>
                    <img
                      src="/assets/icon-show.png"
                      alt="Visualizar usuário"
                      title="Visualizar usuário"
                      className="view-icon"
                    />
                  </Link>
                </td>
                <td>{user.nomeUsuario}</td>
                <td>{user.loginUsuario}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">Nenhum usuário encontrado</td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="valves-container">
        <h2>Válvulas Vinculadas</h2>
        {valves.length > 0 ? (
          <ul>
            {valves.map((valve) => (
              <li key={valve.id}>
                <Link to={`/admin/valvulas/${valve.id}`}>{valve.tag}</Link>
              </li>
            ))}
          </ul>
        ) : (
          <p>Não há válvulas vinculadas a este cliente.</p>
        )}
      </div>

      <div className="button-group">
        {isEditing ? (
          <button onClick={handleSave}>Salvar Alterações</button>
        ) : (
          <button onClick={() => setIsEditing(true)}>Editar Cadastro</button>
        )}
        <button onClick={() => navigate(-1)} className="btn-secondary">
          {" "}
          Voltar{" "}
        </button>
        <button onClick={handleDelete} className="btn-delete">
          Deletar Cliente
        </button>
      </div>
    </Container>
  );
}

export default ClientDetails;

import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import "./styles.css";
import Container from "../../../components/Container";
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../../services/firebase";

const { Password } = Input;

function UserDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  // Buscar detalhes do usuário
  const fetchUserDetails = useCallback(async () => {
    try {
      const ref = doc(db, "usuarios", id);
      const snapshot = await getDoc(ref);
      const data = { id: snapshot.id, ...snapshot.data() };

      const refClient = doc(db, "clientes", data.cliente_id);
      const snapshotClient = await getDoc(refClient);
      const dataClient = { id: snapshotClient.id, ...snapshotClient.data() };

      setClient({ ...dataClient, ...data });
    } catch (error) {
      console.error("Erro ao buscar detalhes do usuário:", error);
    }
  }, [id]);

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClient((prevClient) => ({
      ...prevClient,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      const ref = doc(db, "usuarios", id);
      await updateDoc(ref, client);

      alert("Usuário atualizado com sucesso!");
      setIsEditing(false);
    } catch (error) {
      console.error("Erro ao atualizar usuário:", error);
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Tem certeza de que deseja excluir este usuário?")) {
      try {
        await deleteDoc(doc(db, "usuarios", id));
        alert("Usuário deletado com sucesso!");
        navigate("/admin/clientes");
      } catch (error) {
        console.error("Erro ao deletar usuário:", error);
        alert(
          "Erro ao deletar usuário. Verifique o console para mais detalhes."
        );
      }
    }
  };

  if (!client) {
    return <div>Carregando...</div>;
  }

  return (
    <Container>
      <div className="client-details-container">
        <h2>Detalhes do Usuário</h2>
        <div className="form-group">
          <label>Nome:</label>
          <input
            type="text"
            name="nomeUsuario"
            value={client.nomeUsuario}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <label>Login:</label>
          <input
            type="text"
            name="loginUsuario"
            value={client.loginUsuario}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <label>Senha:</label>
          <Password
            name="senhaUsuario"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            value={client.senhaUsuario}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
      </div>
      <h2>Empresa Vinculada</h2>
      <table className="client-table">
        <thead>
          <tr>
            <th>Ação</th>
            <th>Nome</th>
            <th>Unidade</th> {/* Ajustado para Unidade */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Link to={`/admin/clientes/${client.id}`}>
                <img
                  src="/assets/icon-show.png"
                  alt="Visualizar cliente"
                  title="Visualizar cliente"
                  className="view-icon"
                />
              </Link>
            </td>
            <td>{client.nomeEmpresa}</td>
            <td>{client.unidade}</td> {/* Campo Unidade */}
          </tr>
        </tbody>
      </table>

      <div className="button-group">
        {isEditing ? (
          <button onClick={handleSave}>Salvar Alterações</button>
        ) : (
          <button onClick={() => setIsEditing(true)}>Editar Cadastro</button>
        )}
        <button onClick={() => navigate(-1)} className="btn-secondary">
          {" "}
          Voltar{" "}
        </button>
        <button onClick={handleDelete} className="btn-delete">
          Deletar Usuário
        </button>
      </div>
    </Container>
  );
}

export default UserDetails;

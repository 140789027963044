import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./styles.css";
import {
  IdcardOutlined,
  HomeOutlined,
  TeamOutlined,
  SettingOutlined,
  LogoutOutlined,
} from "@ant-design/icons"; // importação dos icones

function Sidebar() {
  const [clientesSubmenuOpen, setClientesSubmenuOpen] = useState(false);
  const [usersSubmenuOpen, setUsersSubmenuOpen] = useState(false);
  const [valvulasSubmenuOpen, setValvulasSubmenuOpen] = useState(false);

  const navigate = useNavigate();
  function logout() {
    localStorage.clear();
    navigate("/login");
  }

  return (
    <div className="sidebar">
      <div className="logo">
        {/* Ajustando o caminho da imagem para o diretório public */}
        <img src="/assets/logo-seepil.png" alt="Seepil Logo" />
      </div>
      <ul className="sidebar-menu">
        <li>
          <button className="menu-button">
            <Link to="/admin/dashboard" className="menu-link">
              <HomeOutlined style={{ marginRight: 8 }} />
              Início
            </Link>
          </button>
        </li>

        {/* Clientes Section */}
        <li>
          <button
            className="menu-button"
            onClick={() => setClientesSubmenuOpen(!clientesSubmenuOpen)}
          >
            <TeamOutlined style={{ marginRight: 8 }} />
            Clientes
            <span className={`arrow ${clientesSubmenuOpen ? "open" : ""}`}>
              &#9662;
            </span>
          </button>
          {clientesSubmenuOpen && (
            <ul className="submenu">
              <li>
                <img
                  src="/assets/next.png"
                  alt="Ícone Submenu"
                  className="submenu-icon"
                />
                <Link to="/admin/cadastro-cliente">Criar Novo</Link>
              </li>
              <li>
                <img
                  src="/assets/next.png"
                  alt="Ícone Submenu"
                  className="submenu-icon"
                />
                <Link to="/admin/clientes">Listar Clientes</Link>
              </li>
            </ul>
          )}
        </li>

        {/* Users Section */}
        <li>
          <button
            className="menu-button"
            onClick={() => setUsersSubmenuOpen(!usersSubmenuOpen)}
          >
            <IdcardOutlined style={{ marginRight: 8 }} />
            Usuários
            <span className={`arrow ${usersSubmenuOpen ? "open" : ""}`}>
              &#9662;
            </span>
          </button>
          {usersSubmenuOpen && (
            <ul className="submenu">
              <li>
                <img
                  src="/assets/next.png"
                  alt="Ícone Submenu"
                  className="submenu-icon"
                />
                <Link to="/admin/cadastro-usuario">Criar Novo</Link>
              </li>
              <li>
                <img
                  src="/assets/next.png"
                  alt="Ícone Submenu"
                  className="submenu-icon"
                />
                <Link to="/admin/usuarios">Listar Usuários</Link>
              </li>
            </ul>
          )}
        </li>

        {/* Válvulas Section */}
        <li>
          <button
            className="menu-button"
            onClick={() => setValvulasSubmenuOpen(!valvulasSubmenuOpen)}
          >
            <SettingOutlined style={{ marginRight: 8 }} />
            Válvulas
            <span className={`arrow ${valvulasSubmenuOpen ? "open" : ""}`}>
              &#9662;
            </span>
          </button>
          {valvulasSubmenuOpen && (
            <ul className="submenu">
              <li>
                <img
                  src="/assets/next.png"
                  alt="Ícone Submenu"
                  className="submenu-icon"
                />
                <Link to="/admin/cadastro-valvula">Criar Nova</Link>
              </li>
              <li>
                <img
                  src="/assets/next.png"
                  alt="Ícone Submenu"
                  className="submenu-icon"
                />
                <Link to="/admin/valvulas">Listar Válvulas</Link>
              </li>
            </ul>
          )}
        </li>

        <li>
          <button onClick={logout} className="menu-button">
            {/* <Link to="/logout" className="menu-link"> */}
            <LogoutOutlined style={{ marginRight: 8 }} />
            Logout
            {/* </Link> */}
          </button>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom"; // Import Routes e Route
import AdminDashboard from "./pages/admin/AdminDashboard";
import ClientForm from "./pages/admin/ClientForm";
import ClientList from "./pages/admin/ClientList";
import ValveForm from "./pages/admin/ValveForm";
import ValveList from "./pages/admin/ValveList"; // Importando o ValveList
import ClientDetails from "./pages/admin/ClientDetails";
import ValveDetails from "./pages/admin/ValveDetails";
import ClientDashboard from "./pages/cliente/ClientDashboard";
import ClientValves from "./pages/cliente/ClientValves";
import ClientUsers from "./pages/cliente/ClientUsers";
import Login from "./pages/Login";
import Users from "./pages/admin/UserList";
import UserForm from "./pages/admin/UserForm";
import UserDetails from "./pages/admin/UserDetails";
import PrivateRoute from "./components/PrivateRoute";

import Documento from "./components/Documento";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to={"/login"} />} />
        <Route path="/pdf" element={<Documento />} />
        {/* Rota para a página de login */}
        <Route path="/login" element={<Login />} />
        {/* Rotas do Admin */}
        <Route
          path="/admin/dashboard"
          element={<PrivateRoute element={<AdminDashboard />} />}
        />
        <Route path="/admin/cadastro-cliente" element={<ClientForm />} />
        <Route path="/admin/clientes" element={<ClientList />} />
        <Route path="/admin/clientes/:id" element={<ClientDetails />} />{" "}
        <Route path="/admin/cadastro-valvula" element={<ValveForm />} />
        <Route path="/admin/valvulas" element={<ValveList />} />{" "}
        <Route path="/admin/valvulas/:id" element={<ValveDetails />} />
        <Route path="/admin/usuarios" element={<Users />} />
        <Route path="/admin/cadastro-usuario" element={<UserForm />} />
        <Route path="/admin/usuario/:id" element={<UserDetails />} />
        {/* Rotas do Cliente */}
        <Route path="/cliente/dashboard" element={<ClientDashboard />} />
        <Route path="/cliente/valvulas" element={<ClientValves />} />
        <Route path="/cliente/usuarios" element={<ClientUsers />} />
      </Routes>
    </Router>
  );
}

export default App;

import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./styles.css";
import Container from "../../../components/Container";
import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../../services/firebase";
import { Upload } from "antd";
import GeneratePDF from "../../../components/GeneratePDF";
import { QRCode } from "antd";

function ValveDetails() {
  const { id } = useParams(); // Obtendo o ID da válvula pela URL
  const navigate = useNavigate();
  const [valve, setValve] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileListPlaqueta, setFileListPlaqueta] = useState([]);
  const [url, setUrl] = useState(null);

  const handleChangeUpload = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const handleChangeUploadPlaqueta = ({ fileList: newFileList }) =>
    setFileListPlaqueta(newFileList);

  function handleCriarQrCode() {
    const link = window.location.href;
    setUrl(link);
  }

  function doDownload(url, fileName) {
    const a = document.createElement("a");
    a.download = fileName;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  function downloadQrCode() {
    const canvas = document.querySelector("canvas");
    if (canvas) {
      const url = canvas.toDataURL();
      doDownload(url, "QRCode.png");
    }
  }

  function base64ToFile(base64) {
    const [header, data] = base64.split(",");
    const mime = header.match(/:(.*?);/)[1];
    const binary = atob(data);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    const blob = new Blob([new Uint8Array(array)], { type: mime });
    const file = new File([blob], `image.png`, { type: mime });
    const fileObj = {
      uid: "-1",
      name: `image.png`,
      status: "done",
      file: file,
      url: URL.createObjectURL(file),
    };
    return fileObj;
  }

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  // Função que pega os detalhes da válvula
  const fetchValveDetails = useCallback(async () => {
    try {
      const ref = doc(db, "valvulas", id);
      const snapshot = await getDoc(ref);
      const data = { id: snapshot.id, ...snapshot.data() };

      const fotoValve = data.fotoValve.map((foto) => base64ToFile(foto));
      setFileList(fotoValve);

      const fotoPlaqueta = data.fotoPlaqueta.map((foto) => base64ToFile(foto));
      setFileListPlaqueta(fotoPlaqueta);

      const refClient = doc(db, "clientes", data.cliente_id);
      const snapshotClient = await getDoc(refClient);
      const dataClient = { id: snapshotClient.id, ...snapshotClient.data() };

      setValve({ ...dataClient, ...data });
    } catch (error) {
      console.error("Erro ao buscar detalhes da válvula:", error);
    }
  }, [id]);

  useEffect(() => {
    fetchValveDetails();
  }, [fetchValveDetails]);

  const handleDelete = async () => {
    if (window.confirm("Tem certeza de que deseja excluir este usuário?")) {
      try {
        await deleteDoc(doc(db, "valvulas", id));
        alert("Usuário deletado com sucesso!");
        navigate("/admin/clientes");
      } catch (error) {
        console.error("Erro ao deletar usuário:", error);
        alert(
          "Erro ao deletar usuário. Verifique o console para mais detalhes."
        );
      }
    }
  };

  if (!valve) {
    return <div>Carregando...</div>;
  }

  // Função para lidar com mudanças nos campos
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValve((prevValve) => ({
      ...prevValve,
      [name]: value,
    }));
  };

  // Função para salvar alterações
  const handleSave = async () => {
    try {
      const fotosValvula = await Promise.all(
        fileList.map((file) => getBase64(file.originFileObj ?? file.file))
      );

      const fotosPlaqueta = await Promise.all(
        fileListPlaqueta.map((file) =>
          getBase64(file.originFileObj ?? file.file)
        )
      );

      const ref = doc(db, "valvulas", id);
      await updateDoc(ref, {
        ...valve,
        fotoValve: fotosValvula,
        fotoPlaqueta: fotosPlaqueta,
      });
      setIsEditing(false);

      alert("Válvula atualizada com sucesso!");
    } catch (error) {
      console.error("Erro ao atualizar válvula:", error);
    }
  };

  return (
    <Container>
      <div className="valve-details-container">
        <h2>Detalhes da Válvula</h2>
        {/* Campos Editáveis */}
        <div className="form-group">
          <label>TAG:</label>
          <input
            type="text"
            name="tag"
            value={valve.tag}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <label>Empresa Nome:</label>
          <input
            type="text"
            name="nomeEmpresa"
            value={valve.nomeEmpresa}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <label>Empresa Unidade:</label>
          <input
            type="text"
            name="unidade"
            value={valve.unidade}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <label>Tipo de Válvula:</label>
          <input
            type="text"
            name="tipoValvula"
            value={valve.tipoValvula}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <label>Fabricante:</label>
          <input
            type="text"
            name="fabricante"
            value={valve.fabricante}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <label>Modelo:</label>
          <input
            type="text"
            name="modelo"
            value={valve.modelo}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <label>Número de Série:</label>
          <input
            type="text"
            name="numeroSerie"
            value={valve.numeroSerie}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: 24 }}>
          {" "}
          {/* inicia css alinhamento */}
          <div className="form-group">
            <label>Diâmetro Inlet:</label>
            <input
              type="text"
              name="diametroInlet"
              value={valve.diametroInlet}
              onChange={handleInputChange}
              disabled={!isEditing}
            />
          </div>
          <div className="form-group">
            <label style={{ height: 24 }}></label>
            <input
              type="text"
              name="unidadeInlet"
              value={valve.unidadeInlet}
              onChange={handleInputChange}
              disabled={!isEditing}
            />
          </div>
        </div>{" "}
        {/* termina css alinhamento */}
        <div className="form-group">
          <label>Classe de Pressão Inlet:</label>
          <input
            type="text"
            name="classePressaoOutlet"
            value={valve.classePressaoOutlet}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <label>Interface Outlet:</label>
          <input
            type="text"
            name="interfaceOutlet"
            value={valve.interfaceOutlet}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <label>Orifício:</label>
          <input
            type="text"
            name="orificio"
            value={valve.orificio}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <label>Fluido de Operação:</label>
          <input
            type="text"
            name="fluido"
            value={valve.fluido}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: 24 }}>
          <div className="form-group">
            <label>Temperatura de Operação:</label>
            <input
              type="text"
              name="temperaturaOperacao"
              value={valve.temperaturaOperacao}
              onChange={handleInputChange}
              disabled={!isEditing}
            />
          </div>
          <div className="form-group">
            <label style={{ height: 24 }}></label>
            <input
              type="text"
              name="unidadeTemperatura"
              value={valve.unidadeTemperatura}
              onChange={handleInputChange}
              disabled={!isEditing}
            />
          </div>
        </div>
        <div className="form-group">
          <label>Set Pressure:</label>
          <input
            type="text"
            name="setPressure"
            value={valve.setPressure}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="unidadeSetPressure"
            value={valve.unidadeSetPressure}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <label>CDTP (Pressão de Ajuste a Frio):</label>
          <input
            type="text"
            name="cdtp"
            value={valve.cdtp}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="unidadeCdtp"
            value={valve.unidadeCdtp}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <label>Contra Pressão:</label>
          <input
            type="text"
            name="contraPressao"
            value={valve.contraPressao}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="unidadeContraPressao"
            value={valve.unidadeContraPressao}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <label>Equipamento Protegido:</label>
          <input
            type="text"
            name="equipamentoProtegido"
            value={valve.equipamentoProtegido}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <label>Local de Instalação:</label>
          <input
            type="text"
            name="localInstalacao"
            value={valve.localInstalacao}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        {/* Seção para Fotos da Válvula */}
        <div className="form-group">
          <label className="titulo-upload">Fotos da Válvula:</label>
          <Upload
            listType="picture-card"
            fileList={fileList}
            onChange={handleChangeUpload}
          >
            {isEditing && <button type="button">UPLOAD</button>}
          </Upload>
        </div>
        {/* Seção para Fotos Plaqueta do Fabricante */}
        <div className="form-group">
          <label className="titulo-upload">Fotos Plaqueta do Fabricante:</label>
          <Upload
            listType="picture-card"
            fileList={fileListPlaqueta}
            onChange={handleChangeUploadPlaqueta}
          >
            {isEditing && <button type="button">UPLOAD</button>}
          </Upload>
        </div>
      </div>
      <div className="button-group">
        {isEditing ? (
          <button onClick={handleSave}>Salvar Alterações</button>
        ) : (
          <button onClick={() => setIsEditing(true)}>Editar Cadastro</button>
        )}
        <button onClick={() => navigate(-1)} className="btn-secondary">
          {" "}
          Voltar{" "}
        </button>
        <button onClick={handleDelete} className="btn-delete">
          Deletar Válvula
        </button>

        <GeneratePDF valvula={valve} />

        <button onClick={handleCriarQrCode} className="btn-secondary">
          Gerar QR Code
        </button>
        <button onClick={downloadQrCode} className="btn-secondary">
          Download QR Code
        </button>
      </div>
      {url && <QRCode value={url} type="canvas" />}
    </Container>
  );
}

export default ValveDetails;

import Container from "../../../components/Container";
import { useState, useEffect } from "react";
import { Input } from "antd";
import { db, auth } from "../../../services/firebase";
import { collection, getDocs, setDoc, doc } from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";

const { Password } = Input;

function UserForm() {
  const [userData, setUserData] = useState({
    nomeUsuario: "",
    loginUsuario: "", // login inserido manualmente pelo admin
    senhaUsuario: "", // senha inserida manualmente pelo admin
    cliente_id: "",
  });

  const [clients, setClients] = useState([]);

  const fetchClientsFirebase = async () => {
    try {
      const snapshot = await getDocs(collection(db, "clientes"));
      const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      setClients(data);
    } catch (error) {
      console.error("Erro ao buscar clientes:", error);
    }
  };

  useEffect(() => {
    fetchClientsFirebase();
  }, []);

  // Atualiza o valor do campo quando o usuário digita
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  // Envia os dados do formulário ao backend
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const usuario = await createUserWithEmailAndPassword(
        auth,
        userData.loginUsuario,
        userData.senhaUsuario
      );
      const id = usuario.user.uid;
      delete userData.senhaUsuario;
      await setDoc(doc(db, "usuarios", id), userData);

      alert("Usuário cadastrado com sucesso!");
    } catch (error) {
      console.error("Erro ao enviar dados ao backend:", error);
      alert(
        "Erro ao cadastrar cliente. Verifique o console para mais detalhes."
      );
    }
  };

  return (
    <Container>
      <div className="client-form-content">
        <div className="client-form-container">
          <h2>Cadastrar novo usuário</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Nome Completo:</label>
              <input
                type="text"
                name="nomeUsuario"
                value={userData.nomeUsuario}
                onChange={handleChange}
                placeholder="ex: Carlos Garcia"
                required
              />
            </div>

            <div className="form-group">
              <label>Login do Usuário (e-mail) :</label>
              <input
                type="email"
                name="loginUsuario"
                value={userData.loginUsuario}
                onChange={handleChange}
                placeholder="ex: carlos@petrobras.com"
                required
              />
            </div>

            <div className="form-group">
              <label>Senha do Usuário:</label>
              <Password
                name="senhaUsuario"
                value={userData.senhaUsuario}
                onChange={handleChange}
                placeholder="ex: pblog-01230950"
                required
              />
            </div>

            <div className="form-group">
              <label>Empresa:</label>

              <select
                className="cliente-select"
                name="cliente_id"
                value={userData.cliente_id}
                onChange={handleChange}
                required
              >
                <option value="">Selecione a Empresa</option>
                {clients.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.nomeEmpresa} - {item.unidade}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Tipo do Usuário:</label>

              <select
                className="cliente-select"
                name="tipoUsuario"
                value={userData.tipoUsuario}
                onChange={handleChange}
                required
              >
                <option value="">Selecione o tipo</option>
                <option value="cliente">Cliente</option>
                <option value="admin">Admin</option>
              </select>
            </div>

            <button type="submit" className="btn-primary">
              Cadastrar Usuário
            </button>
          </form>
        </div>
      </div>
    </Container>
  );
}

export default UserForm;

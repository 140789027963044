import Container from "../../../components/clientes/Container"; //puxa header e sidebar
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import { db } from "../../../services/firebase";
import { collection, getDocs } from "firebase/firestore";

function ClientUsers() {
  const [users, setUsers] = useState([]);

  const fetchClientsFirebase = async () => {
    const snapshot = await getDocs(collection(db, "clientes"));
    const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    return data;
  };
  //lista de usuarios
  const fetchUsersFirebase = async () => {
    try {
      const snapshot = await getDocs(collection(db, "usuarios"));
      const usuarios = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const clientes = await fetchClientsFirebase();

      const data = usuarios.map((usuario) => {
        const client = clientes.find((c) => c.id === usuario.cliente_id);

        return {
          ...client,
          ...usuario,
        };
      });

      setUsers(data);
    } catch (error) {
      console.error("Erro ao buscar usuário:", error);
    }
  };

  useEffect(() => {
    fetchUsersFirebase();
  }, []);

  return (
    <Container>
      <div className="client-list-container">
        <h2>Usuários Cadastrados</h2>

        <table className="client-table">
          <thead>
            <tr>
              <th>Ação</th>
              <th>Nome</th>
              <th>Login</th>
              <th>Empresa</th>
              <th>Unidade</th>
            </tr>
          </thead>
          <tbody>
            {users.length > 0 ? (
              users.map((client) => (
                <tr key={client.id}>
                  <td style={{ width: "25px" }}>
                    <Link to={`/cliente/usuario/${client.id}`}>
                      <img
                        src="/assets/icon-show.png"
                        alt="Visualizar cliente"
                        title="Visualizar usuário"
                        className="view-icon"
                      />
                    </Link>
                  </td>
                  <td>{client.nomeUsuario}</td>
                  <td>{client.loginUsuario}</td>
                  <td>{client.nomeEmpresa}</td>
                  <td>{client.unidade}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">Nenhum usuário encontrado</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Container>
  );
}

export default ClientUsers;

import React, { useState, useEffect } from "react"; //hook ---- useState armazena e useEffect puxa o que for armazenado (ele dispara uma ação quand a pagina carrega)
import "./styles.css";

function Header({ userName, userAvatar }) {
  // Definindo avatar e nome padrão caso nenhum seja fornecido
  const avatarPath = userAvatar || "/assets/avatar.jpg"; // Imagem padrão caso não seja fornecida

  const [nome, setNome] = useState(""); // "" para string  , 0 para numeros [] para array

  useEffect(() => {
    const usuario = JSON.parse(sessionStorage.getItem("seepilUsuario")); //nome q criei no localstorag
    setNome(usuario?.nomeUsuario);
  }, []); // deixando o array vazio ele só carrega uma vez

  return (
    <header className="header">
      <div className="header-left">
        <input type="text" placeholder="Pesquisar..." className="search-bar" />
      </div>
      <div className="header-right">
        <span className="user-name">{nome}</span>
        <img src={avatarPath} alt="Avatar do Usuário" className="user-avatar" />
      </div>
    </header>
  );
}

export default Header;

import React, { useState } from "react";
import "./styles.css";
import Container from "../../../components/Container";

import InputMask from "react-input-mask";
import { db } from "../../../services/firebase";
import { collection, addDoc } from "firebase/firestore";

function ClientForm() {
  const [clientData, setClientData] = useState({
    nomeEmpresa: "",
    cnpj: "",
    unidade: "",
  });

  const [successMessage, setSuccessMessage] = useState("");

  // Atualiza o valor do campo quando o usuário digita
  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Envia os dados do formulário ao backend
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(db, "clientes"), clientData);
      console.log("Cliente cadastrado com sucesso:", docRef.id);
      // Limpar o formulário após o envio bem-sucedido
      setClientData({
        nomeEmpresa: "",
        cnpj: "",
        unidade: "",
      });
      // Define a mensagem de sucesso
      setSuccessMessage("Cliente cadastrado com sucesso!");
      // Limpa a mensagem de sucesso após 5 segundos
      setTimeout(() => {
        setSuccessMessage("");
      }, 5000);
    } catch (error) {
      console.error("Erro ao enviar dados ao backend:", error);
      alert(
        "Erro ao cadastrar cliente. Verifique o console para mais detalhes."
      );
    }
  };

  return (
    <Container>
      <div className="client-form-content">
        <div className="client-form-container">
          <h2>Cadastrar Novo Cliente</h2>
          {successMessage && (
            <p className="success-message">{successMessage}</p>
          )}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Nome da Empresa:</label>
              <input
                type="text"
                name="nomeEmpresa"
                value={clientData.nomeEmpresa}
                onChange={handleChange}
                placeholder="Digite o nome da Empresa"
                required
              />
            </div>

            <div className="form-group">
              <label>CNPJ:</label>
              <InputMask
                mask="99.999.999/9999-99"
                id="cnpj"
                name="cnpj"
                value={clientData.cnpj}
                onChange={handleChange}
                placeholder="Digite o CNPJ"
                className="input-cnpj"
              />
            </div>

            <div className="form-group">
              <label>Unidade:</label>
              <input
                type="text"
                name="unidade"
                value={clientData.unidade}
                onChange={handleChange}
                placeholder="ex: PB-LOG"
                required
              />
            </div>

            <button type="submit" className="btn-primary">
              Cadastrar Cliente
            </button>
          </form>
        </div>
      </div>
    </Container>
  );
}

export default ClientForm;

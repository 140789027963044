import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Importando useNavigate
import "./styles.css";
import { auth } from "../../services/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../services/firebase";

function Login() {
  const [credentials, setCredentials] = useState({ login: "", senha: "" });
  const navigate = useNavigate(); // Criando uma instância do hook useNavigate

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const pegarUsuario = async (id) => {
    const ref = doc(db, "usuarios", id);
    const result = await getDoc(ref);

    const data = result.data();
    const tipo = data?.tipoUsuario;

    sessionStorage.setItem("seepilUserID", id);
    sessionStorage.setItem("seepilUsuario", JSON.stringify(data));
    console.log(tipo);
    navigate(`/${tipo}/dashboard`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        credentials.login,
        credentials.senha
      );

      if (user.user.uid) {
        await pegarUsuario(user.user.uid);
      }
    } catch (error) {
      console.error("Erro ao fazer login:", error);
      alert("Login falhou! Verifique suas credenciais.");
    }
  };

  return (
    <div className="login-background">
      <div className="login-container">
        <img
          src="/assets/logo-branca1.png"
          alt="Seepil Logo"
          className="login-logo"
        />
        <form onSubmit={handleSubmit} className="login-form">
          <h2>Login</h2>
          <div className="form-group">
            <label>Login:</label>
            <input
              type="text"
              name="login"
              value={credentials.login}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Senha:</label>
            <input
              type="password"
              name="senha"
              value={credentials.senha}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="btn-primary">
            Entrar
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;

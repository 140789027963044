import React, { useState, useEffect } from "react";
import "./styles.css";
import Container from "../../../components/Container";
import { Upload } from "antd";
import { db } from "../../../services/firebase";
import { collection, getDocs, addDoc } from "firebase/firestore";

function ValveForm() {
  const [valveData, setValveData] = useState({
    tag: "",
    cliente_id: "", // Para armazenar o ID do cliente selecionado
    tipoValvula: "",
    fabricante: "",
    modelo: "",
    numeroSerie: "",
    diametroInlet: "",
    unidadeInlet: "pol",
    classePressaoInlet: "",
    conexaoInlet: "",
    diametroOutlet: "",
    unidadeOutlet: "pol",
    classePressaoOutlet: "",
    interfaceOutlet: "",
    orificio: "",
    fluido: "",
    temperaturaOperacao: "",
    unidadeTemperatura: "°C",
    setPressure: "",
    unidadeSetPressure: "kgf/cm²",
    cdtp: "",
    unidadeCdtp: "kgf/cm²",
    contraPressao: "",
    unidadeContraPressao: "kgf/cm²",
    equipamentoProtegido: "",
    localInstalacao: "",
    fotoValve: null, // Para armazenar o arquivo da foto da válvula
    fotoPlaqueta: null, // Para armazenar o arquivo da plaqueta do fabricante
  });

  const [fileList, setFileList] = useState([]);
  const [fileListPlaqueta, setFileListPlaqueta] = useState([]);

  const handleChangeUpload = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const handleChangeUploadPlaqueta = ({ fileList: newFileList }) =>
    setFileListPlaqueta(newFileList);

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const [clientes, setClientes] = useState([]); // Estado para armazenar a lista de clientes

  // Função para buscar a lista de clientes
  useEffect(() => {
    const fetchClientesFirebase = async () => {
      try {
        const snapshot = await getDocs(collection(db, "clientes"));
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setClientes(data);
      } catch (error) {
        console.error("Erro ao buscar clientes:", error);
      }
    };
    fetchClientesFirebase();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValveData({ ...valveData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const filesUpload = await Promise.all(
      fileList.map((file) => getBase64(file.originFileObj))
    );

    const filesUploadPlaqueta = await Promise.all(
      fileListPlaqueta.map((file) => getBase64(file.originFileObj))
    );

    //Enviar dados ao backend
    try {
      await addDoc(collection(db, "valvulas"), {
        ...valveData,
        fotoValve: filesUpload,
        fotoPlaqueta: filesUploadPlaqueta,
      });

      alert("Válvula cadastrada com sucesso!");
    } catch (error) {
      console.error("Erro ao cadastrar válvula:", error);

      alert("Erro ao cadastrar válvula.");
    }
  };

  return (
    <Container>
      <div className="valve-form-content">
        <div className="valve-form-container">
          <h2>Cadastrar Válvula</h2>
          <form onSubmit={handleSubmit}>
            <label>Tag:</label>
            <input
              type="text"
              name="tag"
              value={valveData.tag}
              onChange={handleChange}
              className="input-texto"
              required
            />

            <label>Cliente:</label>
            <select
              name="cliente_id"
              value={valveData.cliente_id}
              onChange={handleChange}
              className="cliente-select"
              required
              style={{
                maxHeight: "150px",
                overflowY: "auto",
                minWidth: "250px",
              }} // Limite de altura e barra de rolagem
            >
              <option value="">Selecione um cliente</option>
              {clientes.map((cliente) => (
                <option key={cliente.id} value={cliente.id}>
                  {cliente.nomeEmpresa} - {cliente.unidade}
                </option>
              ))}
            </select>

            <label>Tipo de Válvula:</label>
            <input
              list="tipoValvula"
              name="tipoValvula"
              value={valveData.tipoValvula}
              onChange={handleChange}
              className="input-datalist"
              required
            />
            <datalist id="tipoValvula" className="input-datalist">
              <option value="Válvula de Alívio" />
              <option value="Válvula de Segurança" />
              <option value="Válvula Piloto-Operada" />
              <option value="Válvula de Alívio & Vácuo" />
              <option value="Válvula de Vácuo" />
            </datalist>

            <label>Fabricante:</label>
            <input
              type="text"
              name="fabricante"
              value={valveData.fabricante}
              onChange={handleChange}
              className="input-texto"
              required
            />

            <label>Modelo:</label>
            <input
              type="text"
              name="modelo"
              value={valveData.modelo}
              onChange={handleChange}
              className="input-texto"
              required
            />

            <label>Número de Série:</label>
            <input
              type="text"
              name="numeroSerie"
              value={valveData.numeroSerie}
              onChange={handleChange}
              className="input-texto"
              required
            />

            <label>Diâmetro Inlet:</label>
            <input
              type="text"
              name="diametroInlet"
              value={valveData.diametroInlet}
              onChange={handleChange}
              className="input-numero"
              required
            />
            <select
              name="unidadeInlet"
              value={valveData.unidadeInlet}
              onChange={handleChange}
              className="input-select"
            >
              <option value="pol">pol</option>
              <option value="mm">mm</option>
            </select>

            <label>Classe de Pressão Inlet:</label>
            <input
              list="classePressaoInlet"
              name="classePressaoInlet"
              value={valveData.classePressaoInlet}
              onChange={handleChange}
              className="input-datalist"
              required
            />
            <datalist id="classePressaoInlet" className="input-datalist">
              <option value="75#" />
              <option value="125#" />
              <option value="150#" />
              <option value="300#" />
              <option value="600#" />
              <option value="900#" />
              <option value="1,500#" />
              <option value="2,500#" />
              <option value="3,000#" />
              <option value="5,000#" />
              <option value="10,000#" />
              <option value="15,000#" />
              <option value="20,000#" />
              {/* Adicione mais opções conforme necessário */}
            </datalist>

            <label>Conexão Inlet:</label>
            <input
              list="conexaoInlet"
              name="conexaoInlet"
              value={valveData.conexaoInlet}
              onChange={handleChange}
              className="input-datalist"
              required
            />
            <datalist id="conexaoInlet" className="input-datalist">
              <option value="FLANGE ASME RF" />
              <option value="FLANGE ASME RTJ" />
              <option value="FLANGE API 6A" />
              <option value="FLANGE DIN" />
              <option value="FLANGE JIS" />
              <option value="VECTOR CLAMP CONNECTOR" />
              <option value="VECTO FLANGELOK" />
              <option value="NORSOK FLANGE COMPACTO" />
              <option value="FLANGE SAE" />
              <option value="ROSCA MACHO NPT" />
              <option value="ROSCA FÊMEA NPT" />
              <option value="ROSCA MACHO BSP" />
              <option value="ROSCA FÊMEA BSP" />
              <option value="CONECTOR SWAGELOK" />
              <option value="CONECTOR AUTOCLAVE" />
              <option value="SOLDA" />
            </datalist>

            <label>Diâmetro Outlet:</label>
            <input
              type="text"
              name="diametroOutlet"
              value={valveData.diametroOutlet}
              onChange={handleChange}
              className="input-select"
              required
            />
            <select
              name="unidadeOutlet"
              value={valveData.unidadeOutlet}
              onChange={handleChange}
              className="input-select"
            >
              <option value="pol">pol</option>
              <option value="mm">mm</option>
            </select>

            <label>Classe de Pressão Outlet:</label>
            <input
              list="classePressaoOutlet"
              name="classePressaoOutlet"
              value={valveData.classePressaoOutlet}
              onChange={handleChange}
              className="input-datalist"
              required
            />
            <datalist id="classePressaoOutlet" className="input-datalist">
              <option value="75#" />
              <option value="125#" />
              <option value="150#" />
              <option value="300#" />
              <option value="600#" />
              <option value="900#" />
              <option value="1,500#" />
              <option value="2,500#" />
              <option value="3,000#" />
              <option value="5,000#" />
              <option value="10,000#" />
              <option value="15,000#" />
              <option value="20,000#" />
            </datalist>

            <label>Interface Outlet:</label>
            <input
              list="interfaceOutlet"
              name="interfaceOutlet"
              value={valveData.interfaceOutlet}
              onChange={handleChange}
              className="input-datalist"
              required
            />
            <datalist id="interfaceOutlet" className="input-datalist">
              <option value="FLANGE ASME RF" />
              <option value="FLANGE ASME RTJ" />
              <option value="FLANGE API 6A" />
              <option value="FLANGE DIN" />
              <option value="FLANGE JIS" />
              <option value="VECTOR CLAMP CONNECTOR" />
              <option value="VECTO FLANGELOK" />
              <option value="NORSOK FLANGE COMPACTO" />
              <option value="FLANGE SAE" />
              <option value="ROSCA MACHO NPT" />
              <option value="ROSCA FÊMEA NPT" />
              <option value="ROSCA MACHO BSP" />
              <option value="ROSCA FÊMEA BSP" />
              <option value="CONECTOR SWAGELOK" />
              <option value="CONECTOR AUTOCLAVE" />
              <option value="SOLDA" />
            </datalist>

            <label>Orifício:</label>
            <input
              list="orificio"
              name="orificio"
              value={valveData.orificio}
              onChange={handleChange}
              className="input-datalist"
              required
            />
            <datalist id="orificio" className="input-datalist">
              <option value='"C" (0,068 in2)' />
              <option value='"D" (0,110 in2)' />
              <option value='"E" (0,196 in2)' />
              <option value='"F" (0,307 in2)' />
              <option value='"G" (0,503 in2)' />
              <option value='"H" (0,785 in2)' />
              <option value='"J" (1,287 in2)' />
              <option value='"K" (1,838 in2)' />
              <option value='"L" (2,853 in2)' />
              <option value='"M" (3,60 in2)' />
              <option value='"N" (4,34 in2)' />
              <option value='"P" (6,38 in2)' />
              <option value='"Q" (11,05 in2)' />
              <option value='"R" (16 in2)' />
              <option value='"T" (26 in2)' />

              {/* Adicione mais opções conforme necessário */}
            </datalist>

            <label>Fluido de Operação:</label>
            <input
              list="fluido"
              name="fluido"
              value={valveData.fluido}
              onChange={handleChange}
              className="input-datalist"
              required
            />
            <datalist id="fluido" className="input-datalist">
              <option value="Água" />
              <option value="Ar Comprimido" />
              <option value="Óleo" />
              <option value="Vapor" />
            </datalist>

            <label>Temperatura de Operação:</label>
            <input
              type="number"
              name="temperaturaOperacao"
              value={valveData.temperaturaOperacao}
              onChange={handleChange}
              className="input-select"
              required
            />
            <select
              name="unidadeTemperatura"
              value={valveData.unidadeTemperatura}
              onChange={handleChange}
              className="input-select"
            >
              <option value="°C">°C</option>
              <option value="°F">°F</option>
              <option value="K">K</option>
            </select>

            <label>Set Pressure:</label>
            <input
              type="number"
              name="setPressure"
              value={valveData.setPressure}
              onChange={handleChange}
              className="input-select"
              required
            />
            <select
              name="unidadeSetPressure"
              value={valveData.unidadeSetPressure}
              onChange={handleChange}
              className="input-select"
            >
              <option value="kgf/cm²">kgf/cm²</option>
              <option value="psi">psi</option>
              <option value="KPa">KPa</option>
            </select>

            <label>CDTP (Pressão de Ajuste a Frio):</label>
            <input
              type="number"
              name="cdtp"
              value={valveData.cdtp}
              onChange={handleChange}
              className="input-select"
              required
            />
            <select
              name="unidadeCdtp"
              value={valveData.unidadeCdtp}
              onChange={handleChange}
              className="input-select"
            >
              <option value="kgf/cm²">kgf/cm²</option>
              <option value="psi">psi</option>
              <option value="KPa">KPa</option>
            </select>

            <label>Contra Pressão:</label>
            <input
              type="number"
              name="contraPressao"
              value={valveData.contraPressao}
              onChange={handleChange}
              className="input-select"
              required
            />
            <select
              name="unidadeContraPressao"
              value={valveData.unidadeContraPressao}
              onChange={handleChange}
              className="input-select"
            >
              <option value="kgf/cm²">kgf/cm²</option>
              <option value="psi">psi</option>
              <option value="KPa">KPa</option>
            </select>

            <label>Equipamento Protegido:</label>
            <input
              type="text"
              name="equipamentoProtegido"
              value={valveData.equipamentoProtegido}
              onChange={handleChange}
              className="input-texto"
              required
            />

            <label>Local de Instalação:</label>
            <input
              type="text"
              name="localInstalacao"
              value={valveData.localInstalacao}
              onChange={handleChange}
              className="input-texto"
              required
            />

            {/* Campo de Upload para Foto da Válvula */}
            <label className="titulo-upload">Fotos da Válvula:</label>
            <Upload
              listType="picture-card"
              fileList={fileList}
              onChange={handleChangeUpload}
            >
              <button type="button">UPLOAD</button>
            </Upload>

            <label className="titulo-upload">
              Fotos Plaqueta do Fabricante:
            </label>
            <Upload
              listType="picture-card"
              fileList={fileListPlaqueta}
              onChange={handleChangeUploadPlaqueta}
            >
              <button type="button">UPLOAD</button>
            </Upload>

            <button type="submit">Cadastrar Válvula</button>
          </form>
        </div>
      </div>
    </Container>
  );
}

export default ValveForm;

import React from "react";
import jsPDF from "jspdf";
import fundo from "../../assets/images/timbrado.jpg";

const GeneratePDF = ({ valvula }) => {
  const handleGeneratePDF = async () => {
    const pdf = new jsPDF("portrait", "mm", "a4"); // Configuração do PDF em A4
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    // Adicionar o fundo timbrado
    const imgTimbrado = await loadImage(fundo);
    pdf.addImage(imgTimbrado, "JPEG", 0, 0, pageWidth, pageHeight);

    // Estilos e configurações de layout
    const marginTop = 100; // Margem superior para o início do conteúdo
    const marginLeft = 20; // Margem esquerda
    const lineHeight = 7; // Altura da linha para espaçamento entre campos
    const fieldLabelWidth = 70; // Largura reservada para o rótulo (ex: Empresa Nome:)

    const blockWidth = 140; // Largura estimada do bloco de dados
    const blockLeft = (pageWidth - blockWidth) / 2; // Calcula o alinhamento central do bloco

    let yOffset = marginTop; // Controle da posição vertical

    // Configuração da fonte para o título
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(16);
    pdf.text("", pageWidth / 2, 30, {
      align: "center",
    });

    // Configuração de fonte para os campos
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(11);

    const campos = [
      { label: "TAG", value: valvula?.tag },
      { label: "Empresa Nome", value: valvula?.nomeEmpresa },
      { label: "Empresa Unidade", value: valvula?.unidade },
      { label: "Tipo de Válvula", value: valvula?.tipoValvula },
      { label: "Fabricante", value: valvula?.fabricante },
      { label: "Modelo", value: valvula?.modelo },
      { label: "Número de Série", value: valvula?.numeroSerie },
      {
        label: "Diâmetro Inlet",
        value: `${valvula?.diametroInlet} ${valvula?.unidadeInlet}`,
      },
      { label: "Classe de Pressão Inlet", value: valvula?.classePressaoInlet },
      { label: "Interface Outlet", value: valvula?.interfaceOutlet },
      { label: "Orifício", value: valvula?.orificio },
      { label: "Fluido de Operação", value: valvula?.fluido },
      { label: "Temperatura de Operação", value: valvula?.temperaturaOperacao },
      { label: "Set Pressure", value: valvula?.setPressure },
      { label: "CDTP", value: valvula?.cdtp },
      { label: "Contra Pressão", value: valvula?.contraPressao },
      { label: "Equipamento Protegido", value: valvula?.equipamentoProtegido },
      { label: "Local de Instalação", value: valvula?.localInstalacao },
    ];

    const rowHeight = 6; // Altura de cada linha
    const backgroundColor1 = "#f5f5f5"; // Cor clara para alternar
    const backgroundColor2 = "#ffffff"; // Cor branca

    // Iteração pelos campos e aplicação de estilos
    campos.forEach((campo, index) => {
      if (campo.value) {
        // Define a cor de fundo alternada
        const backgroundColor =
          index % 2 === 0 ? backgroundColor1 : backgroundColor2;

        // Desenha o retângulo de fundo
        pdf.setFillColor(backgroundColor);
        pdf.rect(
          blockLeft,
          yOffset - rowHeight + 2,
          blockWidth,
          rowHeight,
          "F"
        ); // Retângulo preenchido

        // Rótulo em maiúsculas
        pdf.setFont("helvetica", "bold");
        pdf.setTextColor(0, 0, 0); // Cor preta para o texto
        pdf.text(`${campo.label.toUpperCase()}:`, blockLeft + 5, yOffset);

        // Valor em maiúsculas
        pdf.setFont("helvetica", "normal");
        pdf.text(
          `${campo.value.toUpperCase()}`,
          blockLeft + fieldLabelWidth + 5,
          yOffset
        );

        yOffset += rowHeight; // Incrementa a posição vertical
      }
    });

    // Salva o PDF
    pdf.save("certificado-valvula.pdf");
  };

  // Função para carregar a imagem
  const loadImage = async (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = (err) => reject(err);
    });
  };

  return (
    <button onClick={handleGeneratePDF} className="btn-generate-pdf">
      Gerar PDF
    </button>
  );
};

export default GeneratePDF;

import fundo from "../../assets/images/timbrado.jpg";
import "./styles.css";

function Documento({ valvula }) {
  return (
    <div style={{ width: 800 }}>
      <img src={fundo} style={{ width: "100% " }} />
      <h1>Equipamento Protegido: {valvula?.equipamentoProtegido}</h1>
      <p>Classe de Pressão Inlet: {valvula?.classePressaoInlet}</p>
    </div>
  );
}

export default Documento;


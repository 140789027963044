import Sidebar from "../Sidebar";
import Header from "../Header";

function Container({ children }) {
  return (
    <div className="admin-dashboard">
      <Sidebar />
      <div className="main-content">
        <Header userName="Ana" userAvatar="/assets/avatar.jpg" />
        {children}
      </div>
    </div>
  );
}

export default Container;
